import React from 'react';
import Colors from '../constants/ColorConstants';

const FingerIcon = ({
  size = 24, 
  color = Colors.brand.primary,
  viewBox = '0 0 24 24',
  ...props 
}) => (
  <svg viewBox="0 0 1024 1024" p-id="7313" width={size} height={size} {...props}>
    <path d="M803.17 396.017c-21.228-21.228-49.45-32.918-79.469-32.918-4.498 0-8.954 0.262-13.354 0.78a115.302 115.302 0 0 0-3.954-4.156c-21.227-21.225-49.449-32.914-79.467-32.914-8.162 0-16.193 0.865-23.986 2.55-16.334-13.929-36.047-22.759-57.177-25.677v-131.1c0-30.018-11.689-58.24-32.914-79.467-21.227-21.229-49.45-32.921-79.47-32.921-30.019 0-58.242 11.691-79.469 32.919-21.228 21.228-32.918 49.45-32.918 79.469v312.391a118.67 118.67 0 0 0-14.67-0.909c-23.924-0.002-47.031 7.151-66.823 20.684-13.188 9.023-24.221 20.378-32.808 33.758-8.274 12.893-13.905 27.081-16.734 42.17-2.83 15.09-2.723 30.354 0.319 45.37 3.109 15.347 9.141 29.721 17.933 42.735 9.711 14.791 83.407 126.849 105.758 155.25 32.882 41.78 70.484 75.162 111.765 99.22 22.093 12.875 45.025 22.844 68.159 29.63 24.719 7.25 49.865 10.927 74.739 10.927 8.561 0 17.211-0.44 25.71-1.307 31.386-3.202 60.548-9.669 86.677-19.222 30.109-11.007 56.519-26.22 78.497-45.215 24.711-21.357 43.789-47.397 56.706-77.396 13.184-30.62 19.869-65.357 19.869-103.246V475.486c0-30.019-11.691-58.241-32.919-79.469z m-31.081 321.404c0 120.967-83.718 171.153-184.246 181.41-6.425 0.656-12.828 0.976-19.213 0.976-76.838 0-150.168-46.485-204.369-115.354-21.531-27.36-102.826-151.214-102.826-151.214-16.975-24.806-10.621-58.687 14.188-75.662 9.407-6.432 20.105-9.515 30.695-9.514 17.362 0.001 34.425 8.292 44.971 23.703l33.703 49.255v-448.44c0-26.721 21.666-48.388 48.388-48.388s48.384 21.666 48.384 48.388V415c0-26.721 21.666-48.388 48.391-48.388 26.721 0 48.384 21.666 48.384 48.388v24.192c0-26.721 21.666-48.384 48.388-48.384s48.388 21.663 48.388 48.384v36.293c0-26.721 21.666-48.387 48.388-48.387s48.388 21.666 48.388 48.387v241.936z" p-id="7314"></path>
  </svg>
);

export default FingerIcon