import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from "query-string";
import UniqloIcon from '../icons/UniqloIcon';
import CouponImg from '../images/uniqlo-resources/coupon/50000.jpg';
import ShareModalFireworksIcon from '../icons/ShareModalFireworksIcon';
import LineIcon from '../images/scoial/icon_LINE_full.png';
import SmsIcon from '../images/scoial/icon_sms.png';
import MobileDetectUtil from '../utils/MobileDetectUtil';
import * as orderActionCreator from '../actions/orderActionCreator';
import * as fbEventActionCreator from '../actions/fbEventActionCreator';
import { Progress } from 'reactstrap';
import LocalStorageUtil from '../utils/LocalStorageUtil';
import * as FBUtil from '../utils/FbUtil';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import CookieUtil from '../utils/CookieUtil';
import XIcon from '../icons/XIcon';
import FireworksBackgroundImg from '../images/sahua.gif';
import RdsBackIcon from '../icons/RdsBackIcon';
import { sendCustomEvent } from '../apis/ConfigAPI';
import { generateRandomString } from '../utils/RandomGeneratorUtils';

const HTTP_REQUEST_URL = window.location.protocol + "//" + window.location.host + '/?ad=share';
class SuccessfullyPage extends Component {
    constructor(props, context) {
        super(props, context);
        const invitation_text = `ユニクロ50,000円の無制限クーポンを限定配布中！`;
        const cacheClickNum = CookieUtil.loadClickNum();
        const cacheShareProgress = CookieUtil.loadShareProgress();
        this.state = {
            state: -1,
            email: '',
            phone: '',
            successfullyText: '',
            failedText: '',
            navList: [
                {
                    title: "Line",
                    img: LineIcon,
                    url: `https://line.me/R/share?text=${encodeURIComponent(invitation_text + " " + HTTP_REQUEST_URL)}`,
                    isClick: false,
                }
            ],
            clickNum: cacheClickNum,
            progress: cacheShareProgress,
            showShareWarningDialog: false,
            showShareSuccessDialog: false,
            warningMessage: '',
        }
        this.goBack = this.goBack.bind(this);
        this.shareLinkToApp = this.shareLinkToApp.bind(this);
        this.updateOrderStatus = this.updateOrderStatus.bind(this);
        this.toggleShareWarningDialog = this.toggleShareWarningDialog.bind(this);
        this.toggleShareSuccessModal = this.toggleShareSuccessModal.bind(this);
        this.shareSuccessEvent = this.shareSuccessEvent.bind(this);
        this.sendPurchaseEvent = this.sendPurchaseEvent.bind(this);
    }

    componentDidMount() {
        const cacheShareProgress = CookieUtil.loadShareProgress();
        if (cacheShareProgress === 5) {
            this.shareSuccessEvent();
        }

        this.updateOrderStatus();

        const { location } = this.props;
        const queryParams = queryString.parse(location.search);

        let successfullyText = '';
        if (queryParams.origin === 'payment') {
            successfullyText = 'ご注文ありがとうございます！クーポンは15営業日以内にあなたのユニクロ会員アカウントに付与されます。その際、メール（%email）またはSMS（%phone）でご連絡いたしますので、ご確認ください！';
        } else {
            successfullyText = 'ご提出ありがとうございます！下のシェアボタンをクリックして、友達にこのキャンペーンを共有すると、5万円分のユニクロクーポンを無料で手に入れることができます！';
        }
        let failedText = '';
        if (queryParams.origin === 'payment') {
            failedText = '支払いを怠ってください！ 後でもう一度試してください。';
        } else {
            failedText = '提出に失敗しました! 後でもう一度試してください。'
        }
        successfullyText = successfullyText.replace('%email', `<span class="tw-text-normal-blue">${queryParams.email}</span>`).replace('%phone', `<span class="tw-text-normal-blue">${queryParams.phone}</span>`)
        this.setState({
            state: parseInt(queryParams.state),
            email: queryParams.email,
            phone: queryParams.phone,
            successfullyText,
            failedText
        });
    }

    updateOrderStatus() {
        const { orderActions, location } = this.props;
        const queryParams = queryString.parse(location.search);
        const { cSiteUrl, paymentIntentId, subscriptionId, origin, orderNo } = queryParams;

        const cachePurchaseEvent = LocalStorageUtil.loadPurchaseEvent();
        if (origin === 'payment' && (cachePurchaseEvent !== 'true')) {
            const orderInfo = LocalStorageUtil.loadOrderDetail();

            if (!orderInfo.email && orderNo && orderNo.trim() !== '') {
                // 调用接口根据订单编号获取订单信息
                orderActions && orderActions.getOrderDetailByOrderNo(orderNo)
                .then((data) => {
                    const orderData = JSON.parse(data.data);
                    this.sendPurchaseEvent(orderData);
                })
                .catch(() => {});
            } else {
                this.sendPurchaseEvent(orderInfo);
            }
        }

        if (origin === 'payment' && paymentIntentId && cSiteUrl && subscriptionId) {
            orderActions && orderActions.updateOrderStatus({
                payment_intent_id: paymentIntentId,
                status: 'succeeded',
                c_site_url: cSiteUrl,
                subscriptionId: subscriptionId,
                curcrency: 2,
            }).then(res => {
                LocalStorageUtil.clearStripePaymentIntent();
                LocalStorageUtil.clearOrderId();
                LocalStorageUtil.clearOrderNumber();
            }).catch(err => console.error(err));

            setTimeout(() => {
                LocalStorageUtil.clearStripePaymentIntent();
                LocalStorageUtil.clearOrderId();
                LocalStorageUtil.clearOrderNumber();
            }, 1000);
        }
    }

    sendPurchaseEvent(orderData) {
        let cachedPurchaseNum = LocalStorageUtil.loadPurchaseEventNum();
        if (cachedPurchaseNum && parseInt(cachedPurchaseNum) >= 1) {
            return;
        }
        cachedPurchaseNum = 0;
        LocalStorageUtil.savePurchaseEventNum(cachedPurchaseNum + 1);

        const { location, fbEventActions } = this.props;
        const queryParams = queryString.parse(location.search);

        let { email, phone, firstName, lastName, state, country, zipCode } = orderData;
        const eventId = generateRandomString(10);
        const eventParams = {
            content_ids: 'apply-coupon',
            content_name: 'Coupon',
            content_type: 'product',
            contents: [{
                id: 'coupon-10000',
                title: 'Coupon',
                quantity: 1,
            }],
            currency: 'JPY',
            num_items: 1,
            value: orderData.insuranceType === StoreStateConstants.PAY_FEE ? (CookieUtil.loadPaymentAmount() || 500) : (CookieUtil.loadPaymentAmountFast() || 1000)
        };
        sendCustomEvent('Purchase');
        FBUtil.trackFBEvent('Purchase', eventParams, eventId);
        const userdata = {
            "em": email ? email : queryParams.email,
            "ph": phone ? phone : queryParams.phone,
            "fn": firstName,
            "ln": lastName,
            "ct": state,
            "st": state,
            "zp": zipCode,
            "country": country,
        }
        fbEventActions && fbEventActions.submitFBConversionEvent('Purchase', eventParams, ((userdata.em && userdata.em.trim() !== '') ? userdata : null), eventId, LocalStorageUtil.loadOrderId());
        setTimeout(() => {
            LocalStorageUtil.clearStripePaymentIntent();
            LocalStorageUtil.clearOrderId();
            LocalStorageUtil.clearOrderNumber();
        }, 2000);
    }

    goBack() {
        const { history } = this.props;
        history && history.goBack();
    }

    shareSuccessEvent() {
        // 添加分享成功事件
        setTimeout(() => {
            this.toggleShareSuccessModal();
        }, 1000);
    }

    shareLinkToApp(item) {
        sendCustomEvent('ClickTheShareButton');
        let { clickNum, progress } = this.state;
        if (clickNum < 7) {
            clickNum = clickNum + 1;
        }
        if (clickNum === 7) {
            this.shareSuccessEvent();
        }
        if (clickNum === 3) {
            this.setState({ warningMessage: '別の友人にシェアしてください。同じ友人にシェアしても統計は行われません。', showShareWarningDialog: true });
        } else if (clickNum === 5) {
            this.setState({ warningMessage: 'グループチャットも共有できる', showShareWarningDialog: true });
        } else {
            if (progress < 5) {
                progress = progress + 1;
            }
        }
        this.setState({ clickNum, progress });
        CookieUtil.saveClickNum(clickNum);
        CookieUtil.saveShareProgress(progress);
        /**
         * 明天裂变的页面要加功能
           分享第一次 进度条20%
           分享第二次 进度条40%
           分享第三次 进度条不变还是40% 出现提示框 请分享给不同的好友，分享给同一个好友不做统计（別の友人にシェアしてください。同じ友人にシェアしても統計は行われません。）
           分享第四次 进度条60%
           分享第五次 进度条不变还是60% 出现提示 框 也可以分享群聊（グループチャットも共有できる）
           分享第六次  进度条80%
           分享第七次  进度条100%
        */
        window.open(item.url, "_blank");   // 打开新窗口
    }

    toggleShareWarningDialog() {
        this.setState({ showShareWarningDialog: !this.state.showShareWarningDialog });
    }

    toggleShareSuccessModal() {
        this.setState({ showShareSuccessDialog: !this.state.showShareSuccessDialog });
    }

    render() {
        const { state, successfullyText, failedText, navList, progress, warningMessage, showShareWarningDialog, showShareSuccessDialog } = this.state;
        const { location } = this.props;
        const queryParams = queryString.parse(location.search);
        const origin = queryParams.origin;
        const progressPrecent = (progress / 5) * 100;
        
        const shareSuccessText = 'ご共有ありがとうございます！クーポンは15営業日以内にあなたのユニクロ会員アカウントに付与されますので、ご確認ください！';
        return (
            <div className='tw-w-full tw-h-full gotham-font tw-text-[#393c41]'>
                {showShareWarningDialog && <div className='tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-bg-[rgba(0,0,0,0.7)] tw-flex tw-items-center tw-justify-center tw-z-[1000]'>
                    <div className='tw-rounded-[15px] tw-w-[500px] phone:tw-w-[80%] animate__animated animate__bounceInDown tw-bg-white tw-px-[15px] tw-py-[15px]'>
                        <div className='tw-w-full tw-flex tw-justify-end'><XIcon color='#333' onClick={this.toggleShareWarningDialog} /></div>
                        <div className='tw-mt-[12px] tw-text-center'>{warningMessage}</div>
                    </div>
                </div>}
                {showShareSuccessDialog && <div className='tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-bg-[rgba(0,0,0,0.3)] tw-flex tw-items-center tw-justify-center tw-z-[1000]'>
                    <img src={FireworksBackgroundImg} alt='background' className='tw-z-[1001] tw-absolute tw-left-[50%] tw-top-[50%] tw-w-full tw-h-[225px] ' style={{ transform: 'translate(-50%, -80%)' }} />
                    <div className='tw-z-[1002] tw-rounded-[15px] tw-w-[500px] phone:tw-w-[75%] animate__animated animate__bounceInDown tw-bg-white tw-px-[15px] tw-py-[15px] tw-relative' style={{ boxShadow: '2px 6px 19px 11px rgba(0,0,0,.125)' }}>
                        <ShareModalFireworksIcon size={110} className="tw-absolute tw-left-[-20px] tw-top-[-37px]" style={{ transform: 'rotate(342deg)' }} />
                        <div className='tw-w-full tw-flex tw-justify-end'><XIcon color='#333' onClick={this.toggleShareSuccessModal} /></div>
                        <div className='tw-text-brand-primary tw-text-[22px] tw-font-bold tw-text-center'>成功を分かち合う！</div>
                        <div className='tw-mt-[12px] tw-text-center' dangerouslySetInnerHTML={{ __html: shareSuccessText }}></div>
                    </div>
                </div>}
                <div className='tw-w-full tw-h-full phone:tw-h-full tw-overflow-scroll'>
                    <div className='tw-z-[100] tw-w-[660px] tw-mx-auto phone:tw-w-full tw-h-[80px] phone:tw-h-[60px] tw-flex tw-justify-between tw-items-center'>
                        <div className='tw-w-1/3 tw-h-full tw-flex tw-items-center phone:tw-pl-[30px] tw-pl-[20px]'><RdsBackIcon onClick={this.goBack} /></div>
                        <UniqloIcon size={80} color='#ff0101' />
                        <div className='tw-w-1/3'></div>
                    </div>
                    <div className='tw-w-[750px] phone:tw-w-full tw-mx-auto phone:tw-px-[20px] phone:tw-w-full'>

                        
                        {(origin === "payment" || origin === "submission") && <React.Fragment>
                            {state === 1 && <div className='tw-flex tw-flex-col tw-justify-between tw-items-center'>
                                <div className='tw-w-full tw-text-[18px] phone:tw-text-[14px] tw-font-[500] tw-text-center  phone:tw-text-left tw-mt-[13px]' dangerouslySetInnerHTML={{ __html: successfullyText }}></div>
                            </div>}
                            {state === 0 && <div className='tw-flex tw-flex-col tw-justify-between tw-items-center'>
                                <div className='tw-w-full tw-text-[18px] phone:tw-text-[14px] tw-font-[500] tw-text-light-red tw-text-center  phone:tw-text-left'>{failedText}</div>
                            </div>}
                        </React.Fragment>}

                        <div className='tw-w-full tw-mt-[0px]'>
                            <img src={CouponImg} alt='product' className="tw-w-[200px] tw-mx-auto tw-mt-[20px]" />
                            <div className='phone:tw-text-[16px] tw-font-[500] tw-text-center tw-font-bold tw-mt-[20px]'>
                                <div>このキャンペーンを友達にシェアして、</div>
                                <div>シェア成功で、<span className='tw-text-brand-primary tw-text-[20px] tw-mr-[6px]'>50,000</span>円分のユニクロクーポンを無料でゲット！</div>
                            </div>
                            <div className='phone:tw-mt-[15px] phone:tw-mb-[15px] tw-mt-[30px] tw-mb-[50px] tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-wrap tw-mx-auto'>
                                {navList.map((item, index) => {
                                    return (<div target='__blank' className='tw-inline-block tw-h-[70px] tw-mr-[20px] phone:tw-mr-0 phone:tw-h-[50px] tw-rounded-full tw-mt-[10px] tw-mr-[10px]' onClick={() => this.shareLinkToApp(item, index)} key={item.title}>
                                        <img src={item.img} alt='app_icon' className='tw-w-full tw-h-full' />
                                    </div>)
                                })}
                            </div>
                            {progress > 0 && <div className='tw-mt-[30px] tw-w-full tw-h-[20px] tw-bg-[#f7f7f7] tw-relative tw-rounded-[10px] tw-overflow-hidden'>
                                <Progress className='tw-absolute tw-left-0 tw-top-0 tw-text-white tw-h-full  tw-text-[12px]' animated bar color="success" value={progressPrecent}>{`${progressPrecent}%`}</Progress>
                            </div>}
                        </div>
                        {/* <div className='tw-w-full tw-flex tw-justify-center tw-items-center tw-mt-[30px] tw-text-white tw-mt-[20px]'>
                            <RDSButton
                                color="primary"
                                onClick={this.backToHome}>
                                <span>家に帰る</span>
                            </RDSButton>
                        </div> */}
                    </div>

                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        orderActions: bindActionCreators(orderActionCreator, dispatch),
        fbEventActions: bindActionCreators(fbEventActionCreator, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(SuccessfullyPage);
